import { Link } from "gatsby"
import React from "react"

const Footer = () => {
  return (
    <div className="max-w-7xl mx-auto p-10 sm:p-20 mt-10 bg-lightblack rounded-xl">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
        <div>
          <h1 className="text-white text-3xl lg:text-4xl font-bold">Circle</h1>
          <p className="text-white text-sm mt-5 opacity-50">
            At Orangethink, our global platform connects professionals from different fields, creating an exclusive interactive circle. Whether it's suppliers and buyers in cross-border e-commerce or authors and readers on our novel platform, everyone shares experiences and fosters innovation in this circle. It’s a network that transcends boundaries and cultures, gathering endless possibilities.
          </p>
          <h1 className="text-white text-base lg:text-lg mt-10 lg:mt-20">
            © copyright{" "}
            <a href="/" className="hover:underline">
              Orangethink HK
            </a>
          </h1>
        </div>
        <div className="place-self-start lg:place-self-end self-center xxs:hidden lg:block">
          <ul className="text-lg lg:text-xl font-poppins font-bold space-y-4">
            <li>
              <Link to="/" className="hover:underline">Home</Link>
            </li>
            <li>
              <Link to="/blog" className="hover:underline">Blog</Link>
            </li>
            <li>
              <Link to="/careers" className="hover:underline">Careers</Link>
            </li>
            <li>
              <Link to="/contact" className="hover:underline">Contact</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Footer
