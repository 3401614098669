/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import Footer from "./footer"
import Navbar from "./navbar"
import { Helmet } from "react-helmet"

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <title>
          OrangeThink 橙意集團 - 跨境電商平台與全球小說平台的創新引領者
        </title>
        <meta
          name="description"
          content="OrangeThink Group was established in Hong Kong and is committed to providing innovative technological solutions through its cross-border e-commerce platform and global novel platform. We harness the power of data and connectivity to drive breakthroughs and success for global businesses and creators in the digital age.橙意集團成立於香港，致力於通過旗下跨境電商平台和全球小說平台提供創新的技術解決方案。我們利用數據和連接的力量，推動全球企業與創作者在數位時代中實現突破與成功。"
        />
        <meta
          name="keywords"
          content="橙意集團, OrangeThink, 跨境電商平台, 全球小說平台, 香港公司, 數位創新, 技術解決方案, 數據驅動, 連接全球用戶, 企業賦能, 創作者平台"
        />
        <meta name="robots" content="index, follow" />
        <meta name="google-site-verification" content="lCksPuO-_TEMZgAeGL9ACmt8M-pWwYfaMNJztjVdfGQ" />
        <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-M8L3J8JW');
          `}
        </script>
      </Helmet>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-M8L3J8JW"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
      <div>
        <Navbar></Navbar>
        {children}
        <Footer></Footer>
      </div>
    </>
  )
}

export default Layout
